import romanApi from "@roman/shared/lib/net/romanApi";
import type { Language, LanguageQuery } from "@roman/shared/types/Language";
import useSWR from "swr";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

export type FantasyPoint =
  | {
      fantasyPoint: number;
      startDate: Date;
      opponent: string;
      played: true;
    }
  | {
      startDate: Date;
      opponent: string;
      played: false;
    };

interface FantasyPointsResponse {
  seasonName: string;
  recentFantasyPoints: FantasyPoint[];
}

interface UseRecentFantasyPointsReturn {
  data?: FantasyPointsResponse;
  error: any;
  isLoading: boolean;
}

const fetcher = async (url: string, headers: AuthHeader, lang: Language) => {
  const response = await romanApi.get<FantasyPointsResponse, LanguageQuery>(url, { lang }, { headers });

  const transformedData: FantasyPointsResponse = {
    seasonName: response.seasonName,
    recentFantasyPoints: response.recentFantasyPoints.map((fp) => ({
      ...fp,
      startDate: new Date(fp.startDate),
    })),
  };

  return transformedData;
};

export const useRecentFantasyPoints = (
  playerId: string,
  roundId: string,
  lang: Language = "en",
): UseRecentFantasyPointsReturn => {
  const header = useAuthHeader();

  const { data, error, isLoading } = useSWR<FantasyPointsResponse>(
    `/soccer/player/average-fp/${playerId}/${roundId}`,
    (url: string) => fetcher(url, header, lang),
  );

  return {
    data,
    error,
    isLoading,
  };
};
