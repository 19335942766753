interface LoadingSpinnerProps {
  size?: "sm" | "md" | "lg" | "xl";
  isSpan?: boolean;
}

export const LoadingSpinner = ({ size = "md", isSpan = false }: LoadingSpinnerProps) => {
  const sizeClasses = {
    sm: "h-4 w-4 border-2",
    md: "h-8 w-8 border-4",
    lg: "h-12 w-12 border-4",
    xl: "h-16 w-16 border-6",
  };

  const Component = isSpan ? "span" : "div";

  return (
    <Component
      className={`
        ${sizeClasses[size]}
        border-white
        inline-block
        animate-spin 
        rounded-full 
        border-solid 
        border-r-transparent 
        align-[-0.125em] 
        motion-reduce:animate-[spin_1.5s_linear_infinite]
      `}
      role="alert"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </Component>
  );
};

export default LoadingSpinner;
