import { type AuthHeader, useAuthHeader } from "@roman/shared/hooks/useAuthHeader";
import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { Transaction } from "@roman/shared/types/transaction";
import { useEffect } from "react";
import useSWR from "swr";

interface useTransactionsResponse {
  transactions: Transaction[];
}

const fetcher = (url: string, headers: AuthHeader): Promise<useTransactionsResponse> => {
  return romanApi.get<useTransactionsResponse>(url, undefined, { headers });
};

export const useTransactions = (userId: string, handleError: (err: RomanApiError) => void) => {
  const header = useAuthHeader();
  const { data, isLoading, error } = useSWR<useTransactionsResponse, RomanApiError>(
    userId ? `/transactions/${userId}` : null,
    (url: string) => fetcher(url, header),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.transactions,
    isLoading,
  };
};
