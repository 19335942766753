import romanApi from "@roman/shared/lib/net/romanApi";
import type { EntryResult, PlayerResult } from "@roman/shared/types/Result";
import { useEffect } from "react";
import useSWR from "swr";
import type { Language, LanguageQuery } from "../../types/Language";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface RoundEntryUnitResultResponse {
  result: {
    status: "live" | "ended";
    entries: EntryResult[];
    players: PlayerResult[];
    prizeWon: string | null;
  };
}
const fetcher = (url: string, headers: AuthHeader, lang: Language) =>
  romanApi.get<RoundEntryUnitResultResponse, LanguageQuery>(url, { lang }, { headers });

const POLLING_INTERVAL = 60006;

export const useRoundEntryUnitResult = (
  userId: string | undefined,
  eventId: string,
  lang: Language = "en",
  dedupingInterval?: number,
) => {
  const header = useAuthHeader();
  const { data, error, isLoading, mutate } = useSWR<RoundEntryUnitResultResponse>(
    `/soccer/result/${eventId}/${userId}`,
    (url: string) => fetcher(url, header, lang),
    {
      dedupingInterval,
    },
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.result.status === "live") {
        mutate();
      }
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [data, mutate]);
  return {
    data,
    isLoading,
    error,
  };
};
