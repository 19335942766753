import { cn } from "@roman/shared/utils";

export const Avatar = ({ photoUrl, className }: { photoUrl: string | undefined; className?: string }) => {
  return (
    <div className={cn("relative w-12 h-12 flex items-center justify-center", className)}>
      <img src="/assets/account-bg.svg" alt="account bg" className="absolute h-full z-10 scale-125" draggable={false} />
      <img
        src={photoUrl || "/assets/default-profile.svg"}
        alt="profile"
        className="absolute h-full object-cover rounded-full scale-90"
        draggable={false}
      />
    </div>
  );
};
