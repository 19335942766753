import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { Round } from "@roman/shared/types/Round";
import { useEffect } from "react";
import useSWR from "swr";
import type { Language, LanguageQuery } from "../../types/Language";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface SoccerRoundsResponse {
  rounds: Round[];
}

interface RoundsQuery extends LanguageQuery {
  entryFeeType: "Points" | "NanoTon";
}

const fetcher = (url: string, headers: AuthHeader, params: RoundsQuery) =>
  romanApi.get<SoccerRoundsResponse, RoundsQuery>(url, params, { headers });

export const useSoccerRounds = (
  entryFeeType: "Points" | "NanoTon",
  handleError: (err: RomanApiError) => void,
  lang: Language = "en",
): { data: SoccerRoundsResponse; isLoading: boolean } => {
  const header = useAuthHeader();
  const { data, error } = useSWR<SoccerRoundsResponse>("/soccer/rounds", (url: string) =>
    fetcher(url, header, { lang, entryFeeType }),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const sortedRounds = data?.rounds.sort((a, b) => a.entryCloseTime - b.entryCloseTime) || [];
  return {
    data: {
      rounds: sortedRounds,
    },
    isLoading: !error && !data,
  };
};
