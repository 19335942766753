import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { ScheduledEntry } from "@roman/shared/types/Schedule";
import { useEffect } from "react";
import useSWR from "swr";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface RoundEntryUnitResultResponse {
  entries: ScheduledEntry[];
}

const fetcher = (url: string, headers: AuthHeader) =>
  romanApi.get<RoundEntryUnitResultResponse>(url, undefined, { headers });

export const useScheduledUserEntries = (
  userId: string | undefined,
  roundEntryUnitId: string,
  handleError: (error: RomanApiError) => void,
) => {
  const header = useAuthHeader();
  const { data, error, isLoading, mutate } = useSWR<RoundEntryUnitResultResponse>(
    `/soccer/scheduled/${roundEntryUnitId}/${userId}`,
    (url: string) => fetcher(url, header),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  useEffect(() => {
    mutate();
  }, [mutate]);

  return {
    data,
    isLoading,
    error,
  };
};
