// Need to import this to use ton/@ton library
import "./polyfill";

import { createRoot } from "react-dom/client";
import "./index.css";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { Root } from "./Root";
import { twaEnv } from "./config/env";
import { init } from "./init";

const container = document.querySelector("#root");

init(retrieveLaunchParams().startParam === "debug" || twaEnv.DEV);

if (container) {
  const root = createRoot(container);
  root.render(<Root />);
} else {
  throw new Error("ルートコンテナが見つかりません");
}
