import { LoadingSpinner } from "@roman/shared/components/LoadingSpinner";
import { cn } from "@roman/shared/utils";

interface ButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const PrimaryButton = ({ text, onClick, className, disabled, isLoading }: ButtonProps) => {
  return (
    <button
      className={cn(
        "w-full py-5 px-2.5 text-black rounded-md text-center",
        disabled
          ? "hover:cursor-not-allowed bg-button-background text-text-disabled"
          : "bg-gradient-to-r from-gradient-yellow to-gradient-green text-black",
        className,
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <p className="text-base font-bold">{text}</p>
      )}
    </button>
  );
};
