import RomanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { CreateEntry } from "@roman/shared/types/CreateEntry";
import { useEffect } from "react";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

type createEntryArg = {
  entryTx: CreateEntry;
};

const postCreateEntryRequest = async (url: string, { arg }: { arg: createEntryArg }, headers: AuthHeader) => {
  return await RomanApi.post(url, arg, { headers });
};

export const useCreateEntry = (handleError: (err: RomanApiError) => void) => {
  const header = useAuthHeader();

  const { data, isMutating, trigger, error } = useSWRMutation<any, RomanApiError, Key, createEntryArg>(
    "/soccer/entry",
    (url: string, args: { arg: createEntryArg }) => postCreateEntryRequest(url, args, header),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    trigger,
    isMutating,
  };
};
