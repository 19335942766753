import * as z from "zod";
// avoid using the shared config in the web and twa unless necessary

const SharedEnvSchema = z.object({
  API_URL: z.string(),
  APP_NAME: z.enum(["web", "twa"]),
});

export type SharedEnv = z.infer<typeof SharedEnvSchema>;

const createEnv = (): SharedEnv => {
  const envVars = Object.entries(import.meta.env).reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr;
    if (key.startsWith("FARM_")) {
      acc[key.replace("FARM_", "")] = value;
    }
    return acc;
  }, {});

  const parsedEnv = SharedEnvSchema.safeParse(envVars);
  if (!parsedEnv.success) {
    throw new Error("Invalid shared env provided");
  }

  return parsedEnv.data;
};

export const env = createEnv();
