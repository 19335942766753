import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import { useEffect } from "react";
import useSWR, { type KeyedMutator } from "swr";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

export interface UserSoccerRoundResponse {
  userRound: {
    id: string;
    roundEntryUnits: {
      id: string;
      userNumEntries: number;
    }[];
  };
}

interface UseSoccerRoundReturn {
  data?: UserSoccerRoundResponse;
  isLoading: boolean;
  mutate: KeyedMutator<UserSoccerRoundResponse>;
}

const fetcher = (url: string, headers: AuthHeader) =>
  romanApi.get<UserSoccerRoundResponse>(url, undefined, { headers });

export const useUserSoccerRoundDetails = (
  roundId: string | undefined,
  userId: string,
  handleError: (err: RomanApiError) => void,
): UseSoccerRoundReturn => {
  const header = useAuthHeader();
  const { data, error, isValidating, mutate } = useSWR<UserSoccerRoundResponse, RomanApiError>(
    roundId ? `/soccer/rounds/${roundId}/${userId}` : null,
    (url: string) => fetcher(url, header),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    isLoading: roundId ? !data && isValidating : false,
    mutate,
  };
};
