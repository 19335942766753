import { type AuthHeader, useAuthHeader } from "@roman/shared/hooks/useAuthHeader";
import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";

interface WithdrawalArg {
  destUserAddress: string;
  nanoTon: string;
  userId: string;
}

interface WithdrawalSuccessResponse extends WithdrawalArg {
  message: string;
}

const postWithdrawalRequest = async (url: string, { arg }: { arg: WithdrawalArg }, headers: AuthHeader) => {
  return romanApi.post<WithdrawalSuccessResponse, WithdrawalArg>(url, arg, { headers });
};

export const usePostWithdrawalRequest = () => {
  const header = useAuthHeader();
  const { data, isMutating, trigger, error } = useSWRMutation<
    WithdrawalSuccessResponse,
    RomanApiError,
    Key,
    WithdrawalArg
  >("/payment/withdrawal", (url: string, args: { arg: WithdrawalArg }) => postWithdrawalRequest(url, args, header));

  return {
    data,
    trigger,
    isMutating,
    isError: error !== undefined,
    error,
  };
};
