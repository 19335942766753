import { type AuthHeader, useAuthHeader } from "@roman/shared/hooks/useAuthHeader";
import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import { useEffect } from "react";
import useSWRMutation from "swr/mutation";

type UpdateUserArg = {
  userId: string;
  photoUrl: string;
  telegramUserName: string;
};

const putUpdateUserRequest = async (url: string, headers: AuthHeader, { arg }: { arg: UpdateUserArg }) => {
  return romanApi.patch(
    `${url}/${arg.userId}`,
    {
      photoUrl: arg.photoUrl,
      telegramUserName: arg.telegramUserName,
    },
    { headers },
  );
};

export const useUpdateUser = (handleError: (err: RomanApiError) => void) => {
  const header = useAuthHeader();
  const { isMutating, trigger, error } = useSWRMutation("/user", (url, args: { arg: UpdateUserArg }) =>
    putUpdateUserRequest(url, header, args),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);
  return {
    error: error,
    isMutating,
    trigger,
  };
};
