import { getEnvErrorMessage } from "@roman/shared/utils/error";
import * as z from "zod";

const EnvSchema = z.object({
  DEV: z
    .string()
    .refine((s) => s === "true" || s === "false")
    .transform((s) => s === "true"),
  API_URL: z.string(),
  PORT: z.string().transform((s) => Number.parseInt(s)),
  TON_NETWORK: z.enum(["mainnet", "testnet"]),
  APP_NAME: z.literal("twa"),
});

export type TwaEnv = z.infer<typeof EnvSchema>;

export const createTwaEnv = (): TwaEnv => {
  const envVars = Object.entries(import.meta.env).reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr;
    if (key.startsWith("FARM_")) {
      acc[key.replace("FARM_", "")] = value;
    }
    return acc;
  }, {});

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(getEnvErrorMessage(parsedEnv.error));
  }

  return parsedEnv.data;
};

export const twaEnv = createTwaEnv();
