import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { Language, LanguageQuery } from "@roman/shared/types/Language";
import type { UserRoundEntryUnit } from "@roman/shared/types/UserRoundEntryUnit";
import { useEffect } from "react";
import useSWR from "swr";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface UserRoundEntryUnitsResponse {
  userRoundEntryUnits: UserRoundEntryUnit[];
}

const fetcher = (url: string, headers: AuthHeader, lang: Language) =>
  romanApi.get<UserRoundEntryUnitsResponse, LanguageQuery>(url, { lang }, { headers });

export const useUserRoundEntryUnits = (
  userId: string | undefined,
  handleError: (err: RomanApiError) => void,
  lang: Language = "en",
) => {
  const header = useAuthHeader();
  const getKey = () => (userId ? `/soccer/roundEntryUnits/users/${userId}` : null);

  const { data, error, isLoading, mutate } = useSWR<UserRoundEntryUnitsResponse>(getKey(), (url: string) =>
    fetcher(url, header, lang),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data ?? { userRoundEntryUnits: [] },
    mutate,
    isLoading,
  };
};
