import { type AuthHeader, useAuthHeader } from "@roman/shared/hooks/useAuthHeader";
import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import { useEffect } from "react";
import useSWR from "swr";

export type TonPriceResponse = {
  price: number;
};

const RETRY_INTERVAL_MS = 15_000; // 15 seconds
const MAX_RETRY_COUNT = 3;

const fetcher = (url: string, headers: AuthHeader) => romanApi.get<TonPriceResponse>(url, undefined, { headers });

export const useTonUsdPrice = (handleError?: (err: RomanApiError) => void) => {
  const header = useAuthHeader();
  const { data, error, isLoading, mutate } = useSWR<TonPriceResponse>("/price/ton", (url) => fetcher(url, header), {
    errorRetryInterval: RETRY_INTERVAL_MS,
    errorRetryCount: MAX_RETRY_COUNT,
  });

  useEffect(() => {
    if (error && handleError) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.price ? Number(data.price.toFixed(2)) : undefined,
    isLoading,
    mutate,
  };
};
