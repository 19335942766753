import type { z } from "zod";

// For error 429, Axios puts the message directly in data. Otherwise, message should be in error.data.message
export type RomanApiError = {
  code?: string;
  message: string;
  status: number;
  response?: { data?: { message?: string } | string };
};

export const getErrorMessage = (err: RomanApiError): string => {
  const data = err.response?.data;
  const message = typeof data === "string" ? data : data?.message || err.message || "An unknown error occurred";
  return err.status ? `Error ${err.status}: ${message}` : message;
};

export const isBadInternetConnectionError = (err: RomanApiError): boolean => {
  const errorMessage = getErrorMessage(err);
  return (
    !err.response && errorMessage === "Network Error" && (err.code === "ERR_NETWORK" || err.code === "ECONNABORTED")
  );
};

export const getEnvErrorMessage = (error: z.ZodError) => {
  const errorMessage = Object.entries(error.flatten().fieldErrors)
    .map(([k, v]) => `- ${k}: ${v}`)
    .join("\n");

  return `Invalid env provided.\nThe following variables are missing or invalid: ${errorMessage}`;
};
