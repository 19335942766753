import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { RoundEntryPrize } from "@roman/shared/types/RoundEntryPrize";
import { useEffect } from "react";
import useSWR from "swr";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

interface RoundEntryPrizeResponse {
  roundEntryPrize: RoundEntryPrize[];
}

const fetcher = (url: string, headers: AuthHeader) =>
  romanApi.get<RoundEntryPrizeResponse>(url, undefined, { headers });

export const useSoccerEntryPrize = (roundId: string, handleError: (err: RomanApiError) => void) => {
  const header = useAuthHeader();
  const { data, error, isLoading } = useSWR<RoundEntryPrizeResponse>(`/prizes/${roundId}`, (url: string) =>
    fetcher(url, header),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data ?? { roundEntryPrize: [] },
    isLoading,
  };
};
