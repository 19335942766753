import type { TelegramUser } from "@/types/TelegramUser";
import { createContext, useContext } from "react";

const UserContext = createContext<TelegramUser | undefined>(undefined);

export const UserProvider = ({ user, children }: { user: TelegramUser; children: React.ReactNode }) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUserContext = (): TelegramUser => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
