import { env } from "@roman/shared/config/env";
import { z } from "zod";
import { useTelegram } from "../../../twa/src/hooks/useTelegram";

export enum AuthProvider {
  Telegram = "telegram",
  Google = "google",
}

const authHeaderSchema = z.object({
  "x-auth-provider": z.nativeEnum(AuthProvider),
  Authorization: z
    .string()
    .refine((s) => s.length > 0, {
      message: "Token is required",
    })
    .refine((s) => s.startsWith("Bearer "), {
      message: "Token must start with 'Bearer '",
    }),
});

export type AuthHeader = z.infer<typeof authHeaderSchema>;
type AuthHeaderInput = z.input<typeof authHeaderSchema>;

const newAuthHeader = (input: AuthHeaderInput): AuthHeader => {
  const parsed = authHeaderSchema.safeParse(input);
  if (!parsed.success) {
    throw new Error("Invalid auth token");
  }
  return parsed.data;
};

export const useAuthHeader = (): AuthHeader => {
  if (env.APP_NAME === "twa") {
    const { initDataRaw } = useTelegram();
    const authToken = newAuthHeader({
      "x-auth-provider": AuthProvider.Telegram,
      Authorization: `Bearer ${initDataRaw}`,
    });
    return authToken;
  }

  return newAuthHeader({
    "x-auth-provider": AuthProvider.Google,
    Authorization: `Bearer ${localStorage.getItem("token")}`, // TODO: Maybe we can get the token from another place.
  });
};
